<template>
  <div class="modal-feedback">
    <esmp-modal
      v-model="show"
      :title="title"
      :width="800"
      class-name="modal-ticket-cancel"
    >
      <div
        v-for="question in questions"
        :key="question.id"
        class="modal-feedback__item"
      >
        <div>{{ question.text }}<span v-if="question.required">*</span></div>
        <esmp-rate
          v-model="question.rating"
          @input="updateErrors(question.id)"
        />
        <esmp-input
          v-if="question.rating <= ratingForComment"
          v-model="question.comment"
          label="Прокомментируйте, пожалуйста, свою оценку"
          :options="options"
          @input="updateErrors(question.id)"
        />
        <div
          v-if="errors.filter((el) => question.id === el.id).length"
          class="modal-feedback__error"
        >
          {{ errors.filter((el) => question.id === el.id)[0].message }}
        </div>
      </div>
      <div slot="footer">
        <esmp-button
          v-if="showFillLaterButton"
          size="small"
          view="outline"
          @click="afterHandler"
        >
          Позже
        </esmp-button>
        <esmp-button
          size="small"
          @click="submitHandler"
        >
          Оценить
        </esmp-button>
        <esmp-button
          v-if="showRejectButton"
          size="small"
          view="outline"
          @click="rejectHandler"
        >
          Отказаться
        </esmp-button>
      </div>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ModalFeedback',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
      title: '',
      remainingDays: 7,
      ratingForComment: 3,
      showRejectButton: false,
      showFillLaterButton: false,
      questions: [],
      valid: true,
      errors: [],
      options: {
        type: 'textarea',
      },
    };
  },
  computed: {
    ...mapState('user', ['loggedInUser']),
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  watch: {
    loggedInUser(val, old) {
      if (val && !old && this.$route.name === 'Home') {
        this.fetchQuestionary();
      }
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    updateErrors(id) {
      this.errors = this.errors.filter((el) => el.id !== id);
    },
    async rejectHandler() {
      const params = {
        email: this.loggedInUser.email,
        questionaryId: this.id,
      };
      await this.$API.feedback.rejectQuestionary({ params })
        .then(() => {
          this.$EsmpNotify.$show('Запрос успешно отправлен', 'success');
        })
        .catch((err) => err)
        .finally(() => {
          this.show = false;
          this.setLoading({ key: 'app', value: false });
        });
    },
    submitHandler() {
      this.valid = true;
      this.questions.forEach((el) => {
        if (el.required) {
          if (!el.rating) {
            this.valid = false;
            this.errors.push({
              id: el.id,
              message: 'Необходимо поставить оценку',
            });
          } else {
            this.errors = [];
          }
          if (el.rating <= this.ratingForComment) {
            if (!el.comment || !el.comment.length) {
              this.valid = false;
              this.errors.push({ id: el.id, message: 'Необходим комментарий' });
            } else {
              this.errors = [];
            }
          }
        }
      });
      if (!this.valid) return;
      const answeredQuestions = this.questions
        .filter((el) => !!el.rating)
        .map((el) => ({
          id: el.id,
          rating: el.rating,
          comment: el.rating <= this.ratingForComment ? el.comment : '',
        }));
      const params = {
        email: this.loggedInUser.email,
        questionaryId: this.id,
      };
      this.$API.feedback.answersQuestionary(answeredQuestions, { params })
        .then(() => {
          this.$EsmpNotify.$show('Спасибо за отзыв! Оценка успешно отправлена', 'success');
        })
        .catch((err) => err)
        .finally(() => {
          this.show = false;
          this.setLoading({ key: 'app', value: false });
        });
    },
    fetchQuestionary() {
      const params = {
        email: this.loggedInUser.email,
      };
      this.$API.feedback.actualQuestionary({ params })
        .then(({ data }) => {
          this.id = data.id;
          this.title = data.title || '«Нравится HelpMe?»';
          this.remainingDays = data.remainingDays;
          this.ratingForComment = data.ratingForComment;
          this.showRejectButton = data.showRejectButton;
          this.showFillLaterButton = data.showFillLaterButton;
          this.questions = data.questions;
          if (this.questions && this.questions.length) {
            setTimeout(() => {
              this.show = true;
            }, 500);
          }
        })
        .catch((err) => err)
        .finally(() => {
          this.setLoading({ key: 'app', value: false });
        });
    },
    afterHandler() {
      const params = {
        email: this.loggedInUser.email,
        questionaryId: this.id,
      };
      this.$API.feedback.fillLaterQuestionary({ params })
        .then(() => {
          this.$EsmpNotify.$show('Запрос успешно отправлен', 'success');
        })
        .catch((err) => err)
        .finally(() => {
          this.show = false;
          this.setLoading({ key: 'app', value: false });
        });
    },
  },
  created() {
    if (this.loggedInUser && this.$route.name === 'Home') {
      this.fetchQuestionary();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-feedback {
  &__error {
    margin: 5px 0 0 0;
    color: $error-color;
    font-size: 13px;
  }
  &__title {
    font-weight: bold;
  }
  &__item {
    margin-bottom: 15px;
  }
}
</style>
